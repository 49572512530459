<template>
  <!-- 审批不通过弹窗，输入原因 -->
  <div>
    <a-modal v-model="modalVisible" title="审批描述" :width="600" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formDatas" :label-col="{span:7}" :wrapper-col="{span:14}">
        <!-- <a-form-model-item label="审批结果" prop="approvestatus">
          <a-select v-model="formDatas.approvestatus">
            <a-select-option value="2">审批通过</a-select-option>
            <a-select-option value="3">审核不通过</a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item label="描述" prop="approvedesc">
          <a-textarea v-model.trim="formDatas.approvedesc" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { approveLift } from 'A/jcgn'
export default {
  props: {
    visible: {
      default: false
    },
    liftid: {
      default: ''
    }
  },
  data() {
    return {
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formDatas: {
        approvestatus: '3',//2-审核通过，3-审核不通过
        approvedesc: '',
      },
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetDatas() {
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      // success fail
      this.$refs.modalForm.validate(valid => {
        if(valid) {
          this.showLoading();
          let params = {
            ...this.formDatas,
            liftids: this.liftid
          };
          approveLift(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              this.$message.success('操作成功');
              this.$emit('get-operation-result', 'success', '操作成功');
              this.modalVisible = false;
            }else {
              this.$message.error(res.errormsg||'操作失败');
            }
          })
        }else {
          return false;
        }
      })
    },
  }
}
</script>